import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import LogoutPage from "./Logout";
import getMessages from "../../functions/getMessages";
import logo from "../../../images/logo-full.png";
import logosmall from "../../../images/logo.png";
import { Typography } from "@material-ui/core";
import pkg from "../../../../package.json";
import Dropdown from "../../components/Menu/Dropdown";
import { Button } from "@material-ui/core";
import {
  Add,
  Category,
  CalendarViewMonth,
  Group,
  GroupAdd,
  People,
  AccountCircle,
  AdminPanelSettings,
  Settings,
  Mail,
  Groups,
  ManageAccounts,
  PersonAdd,
  Quickreply,
  DisplaySettings,
  Key,
} from "@mui/icons-material";
import CircleButton from "../../components/Button/CircleButton";
import SelectionModalComponent from "../../components/Modal/Modal";
import { THEME_GRAY } from "../../../CONST";

let userData = localStorage.getItem("userDetails", "undefined");

const Header = () => {
  const [actionModalVisible, setActionModalVisible] = useState(false);
  const [usersModalVisible, setUsersModalVisible] = useState(false);
  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const [currentUser] = useState(
    JSON.parse(localStorage.getItem("userDetails", "undefined"))
  );
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    getMessages("messages");
  }, []);
  const updateUnreadedRef2 = useRef(null);
  useEffect(() => {
    updateUnreadedRef2.current = setInterval(() => {
      getMessages("messages");
    }, 5000);
    return () => {
      clearInterval(updateUnreadedRef2.current);
    };
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginRight: "2.5em",
          gap: "1em",
          height: "90px",
        }}
      >
        {currentUser.tipo < 2 && (
          <>
            <Dropdown
              actionButton={<CircleButton icon={<Add />} text="Crea" />}
              items={[
                {
                  title: "Crea nuovo palinsesto",
                  icon: <CalendarViewMonth />,
                  onClick: () => (window.location.href = "nuovo-palinsesto"),
                },
                {
                  title: "Crea nuova categoria",
                  icon: <Category />,
                  onClick: () => (window.location.href = "nuova-categoria"),
                },
              ]}
            />
            <Dropdown
              actionButton={
                <CircleButton icon={<Groups />} text="Gestione utenti" />
              }
              items={[
                {
                  title: "Crea nuovo collaboratore",
                  icon: <PersonAdd />,
                  onClick: () => (window.location.href = "nuovo-utente"),
                },
                {
                  title: "Crea nuovo cliente",
                  icon: <PersonAdd />,
                  onClick: () =>
                    (window.location.href =
                      "/impostazioni?tab=anagrafiche&action=aggiunta"),
                },
                {
                  title: "Gestisci utenti",
                  icon: <Groups />,
                  onClick: () => (window.location.href = "gestione-utenti"),
                },
                {
                  title: "Gestisci operatori",
                  icon: <ManageAccounts />,
                  onClick: () => (window.location.href = "utenti"),
                },
                {
                  title: "Gestisci licenze player",
                  icon: <Key />,
                  onClick: () =>
                    (window.location.href =
                      "impostazioni?tab=gestione-licenze"),
                },
              ]}
            />
            <Dropdown
              actionButton={
                <CircleButton icon={<Settings />} text="Impostazioni" />
              }
              items={[
                {
                  title: "Generale",
                  icon: <DisplaySettings />,
                  onClick: () =>
                    (window.location.href = "impostazioni?tab=dati-aziendali"),
                },
                {
                  title: "Template comunicazioni",
                  icon: <Quickreply />,
                  onClick: () =>
                    (window.location.href =
                      "messaggi?tab=impostazioni-messaggi"),
                },
              ]}
            />
          </>
        )}
        <Dropdown
          actionButton={
            <CircleButton icon={<AccountCircle />} text="Account" />
          }
          items={[
            {
              title: "Amministrazione",
              icon: <AdminPanelSettings />,
              onClick: () => (window.location.href = "app-profile"),
              hidden: currentUser.tipo > 2,
            },
            {
              title: "Messaggi",
              icon: <Mail />,
              onClick: () =>
                (window.location.href = "messaggi?tab=tutti-i-messaggi"),
            },
            {
              component: <LogoutPage />,
            },
          ]}
        />
      </div>
    </div>
  );
};
export default Header;
